import { FunctionComponent, ReactElement } from "react";

function format(input: Date | null | undefined) : string {

    if(input == null || input == undefined) {
        return "";
    }

    let day: number | string, month : number | string, year : number;

    let date = new Date(input);

    day = date.getDate()+1;
    month = date.getMonth() + 1;
    year = date.getFullYear();

    day = day.toString().padStart(2, '0');

    month = month.toString().padStart(2, '0');

    return `${day}.${month}.${year}`;
}

function parse(input : string | null | undefined) : Date | null | undefined {

    if(input == null) {
        return null;
    }

    if(input == undefined) {
        return undefined;
    }

    let part = input.split(".");
    let date = new Date(parseInt(part[2]), parseInt(part[1]) - 1, parseInt(part[0]));
    return date;
}

export default {
    format,
    parse
}