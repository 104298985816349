import axios from "axios";
import { BlobOptions } from "buffer";
import { Roster } from "../types/types";
import { API_URL } from "./api-url";
import authHeader from "./auth-header";



async function getAll(){
    return await axios
        .get<Roster[]>(API_URL + "rosters", {headers: authHeader()});
}

async function saveAsync(data : any) {
    return await axios
        .post(API_URL + "rosters", data, { headers: authHeader()});
}

async function updateAsync(data: any) {
    return await axios
        .put(API_URL + "rosters", data, { headers: authHeader()});
}

async function deleteAsync(id: any) {
    return await axios
        .delete(API_URL + "rosters/" + id, { headers: authHeader()});
}

async function getByIdAsync(id: any) {
    return await axios
        .get<Roster>(API_URL+ "rosters/" + id, {headers: authHeader()});
}

export default {
    getAll,
    saveAsync,
    updateAsync,
    deleteAsync,
    getByIdAsync,
};