import { FunctionComponent, MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { Roster } from "../../types/types";
import rosterService from "../../services/roster.service";
import DateHelper from "../../helpers/DateHelper";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";

library.add(faPenToSquare, faTrash);

export const RostersPage : FunctionComponent = () : ReactElement => {
    const navigate = useNavigate();
    const [rosters, setRosters] = useState<Roster[]>([])

    useEffect(()=> {
        async function loadData() {
            await rosterService.getAll().then(
                result => {
                    setRosters(result.data);
                    console.log(result.data);
                }
            )
        };
        loadData();
    }, []);

    const onClickEditButton = (id: number | null) => {
        navigate("/dienstplan/" + id);
    }

    return (
        <div className="panel">
            <div className="toolbar">
                <button className="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#genderModal">Hinzufügen</button>
            </div>
            <table className="km-table km-table-striped">
                <thead>
                    <tr>
                        <th>Einrichtung</th>
                        <th style={{width: "100px"}}>Basisplan</th>
                        <th style={{width: "100px"}}>Gültig ab</th>
                        <th style={{width: "1%", whiteSpace: "nowrap"}}></th>
                    </tr>
                </thead>
                <tbody>
                { rosters && rosters.map((roster, idx) => 
                    <tr key={idx}>
                        <td>{roster.facility?.name}</td>
                        <td>{(roster.isBaseRoster) ? "ja" : "nein"}</td>
                        <td>{DateHelper.format(roster.valid)}</td>
                        <td>
                            <div style={{whiteSpace: "nowrap"}}>
                                <button className="btn" onClick={() => onClickEditButton(roster.id)}><FontAwesomeIcon icon="pen-to-square" /></button>
                                
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}