import { useEffect, useState } from "react";
import { headline } from "../../extensions/pageSlice";
import { useDispatch } from "react-redux";
import facilitiesService from "../../services/facilities.service";
import fontawesome from "../../extensions/fontawesome";
import { useNavigate } from "react-router";

const FacilitiesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        dispatch(headline("Einrichtungen"));
        loadData();
    }, []);
    
    function loadData() {
        facilitiesService.getAll().then(res => setFacilities(res.data));
    }

    const onClickAddButton = () => {
        navigate("/einrichtungen/neu");
    }

    const onClickEditButton = (id) => {
        navigate("/einrichtungen/" + id);
    }

    return(
        <div className="panel">
            <div className="toolbar">
                <button className="btn btn-outline-default btn-sm" onClick={onClickAddButton}>Hinzufügen</button>
            </div>
            <table className="km-table km-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Kürzel</th>
                        <th>Kostenst.</th>
                        <th>Öffnungszeit</th>
                        <th>Einrichtungsart</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {facilities.length > 0 ? (facilities.map((row, idx) =>
                        <tr key={idx}>
                            <td>{row.name}</td>
                            <td>{row.short}</td>
                            <td>{row.costCentre}</td>
                            <td>{row.weeklyOpenTime}</td>
                            <td>{row.facilityType.name}</td>
                            <td style={{width: "100px"}}>
                                <button className="btn" onClick={() => onClickEditButton(row.id)}><fontawesome.penToSquare /></button>
                                {/*<button className="btn"><fontawesome.trash /></button>*/}
                            </td>
                        </tr>)) 
                        : 
                        (<tr>
                            <td colSpan="8">Kein Daten vorhanden</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
};

export default FacilitiesPage;