import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { headline } from "../../extensions/pageSlice";
import trainingqualificationService from "../../services/trainingqualifications.service";
import bsmodal from "../../../node_modules/bootstrap/js/dist/modal";
import { useForm } from "react-hook-form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faPenToSquare, faTrash);

const TrainingQualifications = () => {
    const dispatch = useDispatch();
    const defaultValues = {
        id: null,
        name: "",
        description: "",
        key: "",
        shareOfProfessional: 0,
    };

    const {register, handleSubmit, reset, setValue, formState: {isDirty, dirtyFields, error}} = useForm({
        defaultValues: defaultValues
    });

    const onClickEditButton = (data) => {
        reset(data);
        const modal = new bsmodal('#modal').show();
    }

    const onClickDeleteButton = (id) => {
        trainingqualificationService.deleteAsync(id)
            .then(() =>
                loadList()
            );
    }

    const handleInvalid = (data, e) => {
        console.log("Invalid");
    }

    const handleSave = (data, e) => {
        if(isDirty) {
            if(data.id == null) {
                trainingqualificationService.saveAsync(data).then(
                    () => {
                        loadList();
                        reset(defaultValues);
                    }
                );
            } else {
                var result = Object.fromEntries(Object.entries(dirtyFields).map(([k, v]) => [k, data[k]]));
                result.id = data.id;
                trainingqualificationService.updateAsync(result).then(
                    (response) => {
                        loadList();
                        reset(defaultValues);
                    }
                )
            }
        }
    }

    const [trainingQualifications, setTrainingQualifications] = useState([]);

    function loadList() {
        trainingqualificationService.getAll().then(res => setTrainingQualifications(res.data));
    }

    useEffect(() => {
        dispatch(headline("Einstellungen - Listen - Ausbildungsabschlüsse"));
        loadList();
    }, []);

    return(
        <div className="panel">
            <div className="toolbar">
                <button className="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#modal">Hinzufügen</button>
            </div>
            <table className="km-table km-table-striped">
                <thead>
                    <tr>
                        <th>Bezeichnung</th>
                        <th>Beschreibung</th>
                        <th style={{width: "100px"}}>Schlüssel</th>
                        <th style={{width: "100px", whiteSpace: "nowrap"}}>Anteil Fachkraft</th>
                        <th style={{width: "1%", whiteSpace: "nowrap"}}></th>
                    </tr>
                </thead>
                <tbody>
                { trainingQualifications && trainingQualifications.map((row, idx) => 
                    <tr key={idx}>
                        <td>{row.name}</td>
                        <td>{row.description}</td>
                        <td className="center">{row.key}</td>
                        <td className="center">{row.shareOfProfessional}</td>
                        <td>
                            <div style={{whiteSpace: "nowrap"}}>
                                <button className="btn" onClick={() => onClickEditButton(row)}><FontAwesomeIcon icon="pen-to-square" /></button>
                                <button className="btn" onClick={() => onClickDeleteButton(row.id)}><FontAwesomeIcon icon="trash" /></button>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>


            <div className="modal fade" id="modal" tabIndex="-1" data-bs-keyboard="false" data-bs-backdrop="static"  aria-labelledby="modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={(e) => handleSubmit(handleSave, handleInvalid)(e)}>
                            <div className="modal-header">
                                Ausbildungsabschluss hinzufügen
                            </div>
                            <div className="modal-body">
                                
                                <div className="input-group mb-3">
                                    <span className="input-group-text d-flex justify-content-center" id="nameLabel" style={{width: "160px"}}>Bezeichnung</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        placeholder="Bezeichnung" 
                                        aria-label="Name" 
                                        aria-describedby="nameLabel"
                                        maxLength="100"
                                        {...register("name", {required: true})} 
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text d-flex justify-content-center" id="descriptionLabel" style={{width: "160px"}}>Beschreibung</span>
                                    <textarea 
                                        //type="text" 
                                        className="form-control" 
                                        name="description"
                                        placeholder="Beschreibung" 
                                        maxLength="250"
                                        {...register("description", {required: false})} 
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text d-flex justify-content-center" id="keyLabel" style={{width: "160px"}}>Schlüssel</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="key"
                                        placeholder="Schlüssel" 
                                        maxLength="3"
                                        {...register("key", {required: true})} 
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text d-flex justify-content-center" id="shareOfProfessionalLabel" style={{width: "160px"}}>Anteil Fachkraft</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="shareOfProfessional"
                                        placeholder="Anteil Fachkraft" 
                                        maxLength="100"
                                        {...register("shareOfProfessional", {required: true})} 
                                    />
                                </div>
                     
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-default btn-sm" data-bs-dismiss="modal">Schließen</button>
                                <button type="submit" className="btn btn-outline-default btn-sm" data-bs-dismiss="modal">Speichern</button>
                            </div>
                            <input type="hidden" name="id" {...register("id")} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TrainingQualifications;