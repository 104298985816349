import { FunctionComponent, ReactElement } from "react";
import { TimeSelectProps } from "../types/timeSelectProps";
import { Select, MenuItem } from "@mui/material";

export const TimeSelect : FunctionComponent<TimeSelectProps> = (props) : ReactElement => {

    return(
        <Select
            onChange={props.onChange}
            onBlur={props.onBlur}
            defaultValue={props.value}
            value={props.value}
        >
            <MenuItem value={0}>{props.text}</MenuItem>
            <MenuItem value={7.5}>07:30</MenuItem>
            <MenuItem value={7.45}>07:45</MenuItem>
            <MenuItem value={8}>08:00</MenuItem>
            <MenuItem value={8.25}>08:15</MenuItem>
            <MenuItem value={8.5}>08:30</MenuItem>
            <MenuItem value={8.45}>08:45</MenuItem>
            <MenuItem value={9}>09:00</MenuItem>
            <MenuItem value={9.25}>09:15</MenuItem>
            <MenuItem value={9.5}>09:30</MenuItem>
            <MenuItem value={9.45}>09:45</MenuItem>
            <MenuItem value={10}>10:00</MenuItem>
            <MenuItem value={10.25}>10:15</MenuItem>
            <MenuItem value={10.5}>10:30</MenuItem>
            <MenuItem value={10.45}>10:45</MenuItem>
            <MenuItem value={11}>11:00</MenuItem>
            <MenuItem value={11.25}>11:15</MenuItem>
            <MenuItem value={11.5}>11:30</MenuItem>
            <MenuItem value={11.45}>11:45</MenuItem>
            <MenuItem value={12}>12:00</MenuItem>
            <MenuItem value={12.25}>12:15</MenuItem>
            <MenuItem value={12.5}>12:30</MenuItem>
            <MenuItem value={12.45}>12:45</MenuItem>
            <MenuItem value={13}>13:00</MenuItem>
            <MenuItem value={13.25}>13:15</MenuItem>
            <MenuItem value={13.5}>13:30</MenuItem>
            <MenuItem value={13.45}>13:45</MenuItem>
            <MenuItem value={14}>14:00</MenuItem>
            <MenuItem value={14.25}>14:15</MenuItem>
            <MenuItem value={14.5}>14:30</MenuItem>
            <MenuItem value={14.45}>14:45</MenuItem>
            <MenuItem value={15}>15:00</MenuItem>
            <MenuItem value={15.25}>15:15</MenuItem>
            <MenuItem value={15.5}>15:30</MenuItem>
            <MenuItem value={15.45}>15:45</MenuItem>
            <MenuItem value={16}>16:00</MenuItem>
            <MenuItem value={16.25}>16:15</MenuItem>
            <MenuItem value={16.5}>16:30</MenuItem>
            <MenuItem value={16.45}>16:45</MenuItem>
            <MenuItem value={17}>17:00</MenuItem>
            <MenuItem value={17.25}>17:15</MenuItem>
            <MenuItem value={17.5}>17:30</MenuItem>
            <MenuItem value={17.45}>17:45</MenuItem>
            <MenuItem value={18}>18:00</MenuItem>
            <MenuItem value={18.25}>18:15</MenuItem>
            <MenuItem value={18.5}>18:30</MenuItem>
        </Select>
    );
};

export default TimeSelect;