import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faPenToSquare, faTrash);

function trash() {
    return <FontAwesomeIcon icon="trash" /> 
}

function penToSquare() {
    return <FontAwesomeIcon icon="pen-to-square" />
}

export default {
    trash,
    penToSquare,
}