import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { headline } from "../../extensions/pageSlice";
import facilitiesService from "../../services/facilities.service";
import FacilityTypeSelect from "../../components/facilityTypeSelect";

const FacilityPage = () => {
    const dispatch = useDispatch();
    const saveButton = useRef();
    const {id} = useParams();
    
    const defaultValues = {
        id: (id > 0) ? id : null,
        name: "",
        short: "",
        costCentre: "",
        facilityNumber: "",
        destatisNumber: "",
        weeklyOpenTime: 0,
        facilityTypeId: null,
    }

    const {register, handleSubmit, reset, formState: {isDirty, dirtyFields, error}} = useForm({
        defaultValues: defaultValues
    });

    const facilityType = register('facilityTypeId', {required: false});

    useEffect(() => {
        saveButton.current.disabled = false;
    }, [isDirty]);

    useEffect(() => {
        (id > 0) ? dispatch(headline("Einrichtung bearbeiten")) : dispatch(headline("Einrichtung hinzufügen"));
        if(id > 0) {
            facilitiesService.getByIdAsync(id)
                .then(res => {
                    reset(res.data);
                });
        }
    },[]);

    const labelWidth = "170px";

    const onAfterLoad = () => {
        reset(defaultValues);
        saveButton.current.disabled = true;
    }

    const handleSave = (data) => {
        if(isDirty) {
            if(data.id == null) {
                facilitiesService.saveAsync(data);
            } else {
                facilitiesService.updateAsync(data);
            }
        }
    }

    return(
        <form onSubmit={handleSubmit(handleSave)}>
            <div className="panel">
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="nameLabel" style={{width: labelWidth}}>Name</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="name"
                                placeholder="Name der Einrichtung" 
                                maxLength="50"
                                {...register("name", {required: true})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="shortLabel" style={{width: labelWidth}}>Art der Einrichtung</span>
                            <FacilityTypeSelect 
                                className="form-control" 
                                onChange={facilityType.onChange}
                                onBlur={facilityType.onBlur}
                                name={facilityType.name}
                                innerRef={facilityType.ref}
                                onAfterLoad={onAfterLoad}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="shortLabel" style={{width: labelWidth}}>Kürzel</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="short"
                                placeholder="Kürzel der Einrichtung" 
                                maxLength="5"
                                {...register("short", {required: true})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="costCentreLabel" style={{width: labelWidth}}>Kostenstelle</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="costCentre"
                                placeholder="Kostenstelle der Einrichtung" 
                                maxLength="5"
                                {...register("costCentre", {required: true})} 
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="facilityNumberLabel" style={{width: labelWidth}}>Einrichtungsnummer</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="facilityNumber"
                                placeholder="Einrichtungsnummer" 
                                maxLength="15"
                                {...register("facilityNumber", {required: true})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="destatisNumberLabel" style={{width: labelWidth}}>Destatis-Nummer</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="destatisNumber"
                                placeholder="Destatis-Nummer" 
                                maxLength="15"
                                {...register("destatisNumber", {required: true})} 
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="weeklyOpenTimeLabel" style={{width: labelWidth}}>Öffnungszeit</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="weeklyOpenTime"
                                placeholder="Wöchentliche Öffnungszeit in Stunden" 
                                maxLength="5"
                                {...register("weeklyOpenTime", {required: true})} 
                            />
                        </div>
                    </div>

                </div>
                <button ref={saveButton} disabled type="submit" className="btn btn-outline-default btn-sm">Speichern</button>
            </div>
            <input type="hidden" name="id" {...register("id", {required: false})} />
        </form>
    )
};

export default FacilityPage;