import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { headline } from "../../extensions/pageSlice";
import groupsService from "../../services/groups.service";
import FacilitySelect from "../../components/facilitySelect";

const GroupPage = () => {
    const dispatch = useDispatch();
    const saveButton = useRef();
    const {id} = useParams();
    
    const defaultValues = {
        id: (id > 0) ? id : null,
        name: "",
        maxChildren: "",
        facilityId: null,
    }

    const {register, handleSubmit, reset, formState: {isDirty, dirtyFields, error}} = useForm({
        defaultValues: defaultValues
    });

    const facility = register('facilityId', {required: false});

    useEffect(() => {
        saveButton.current.disabled = false;
    }, [isDirty]);

    useEffect(() => {
        (id > 0) ? dispatch(headline("Gruppe bearbeiten")) : dispatch(headline("Gruppe hinzufügen"));
        if(id > 0) {
            groupsService.getByIdAsync(id)
                .then(res => {
                    reset(res.data);
                });
        }
    },[]);

    const labelWidth = "130px";

    const onAfterLoad = () => {
        reset(defaultValues);
        saveButton.current.disabled = true;
    }

    const handleSave = (data) => {
        if(isDirty) {
            if(data.id == null) {
                groupsService.saveAsync(data);
            } else {
                groupsService.updateAsync(data);
            }
        }
    }

    return(
        <form onSubmit={handleSubmit(handleSave)}>
            <div className="panel">
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="nameLabel" style={{width: labelWidth}}>Name</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="name"
                                placeholder="Name der Gruppe" 
                                maxLength="50"
                                {...register("name", {required: true})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="shortLabel" style={{width: labelWidth}}>Einrichtung</span>
                            <FacilitySelect 
                                className="form-control" 
                                onChange={facility.onChange}
                                onBlur={facility.onBlur}
                                name={facility.name}
                                innerRef={facility.ref}
                                onAfterLoad={onAfterLoad}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="weeklyOpenTimeLabel" style={{width: labelWidth}}>max. Kinder</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="maxChildren"
                                placeholder="max. Anzahl Kinder" 
                                maxLength="5"
                                {...register("maxChildren", {required: true})} 
                            />
                        </div>
                    </div>

                </div>
                <button ref={saveButton} disabled type="submit" className="btn btn-outline-default btn-sm">Speichern</button>
            </div>
            <input type="hidden" name="id" {...register("id", {required: false})} />
        </form>
    )
};

export default GroupPage;