import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import fontawesome from "../../extensions/fontawesome";
import { headline } from "../../extensions/pageSlice";
import DateHelper from "../../helpers/DateHelper";
import employeesService from "../../services/employees.service";

const Employees = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);

    function loadList() {
        employeesService.getAll().then(res => setEmployees(res.data));
    }

    const onClickEditButton = (id) => {
        navigate("/mitarbeiter/" +id);
    }

    useEffect(() => {
        dispatch(headline("Mitarbeiter"));
        loadList();
    }, []);

    const onClickAddButton = () => {
        navigate("/mitarbeiter/neu");
    }

    return (
        <div className="panel">
            <div className="toolbar">
                <button className="btn btn-outline-default btn-sm" onClick={onClickAddButton}>Hinzufügen</button>
            </div>
            <table className="km-table km-table-striped">
                <thead>
                    <tr>
                        <th>P.-Nr.</th>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>Geschlecht</th>
                        <th>Geburtstag</th>
                        <th>Ausbildungsabschluss</th>
                        <th>Einrichtung</th>
                        <th>Gruppe</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {employees.length > 0 ? (employees.map((row, idx) =>
                        <tr key={idx}>
                            <td>{row.staffNumber}</td>
                            <td>{row.firstName}</td>
                            <td>{row.lastName}</td>
                            <td>{row.gender.name}</td>
                            <td>{DateHelper.format(row.birthdate)}</td>
                            <td>{row.trainingQualification?.name}</td>
                            <td>{row.facility?.name}</td>
                            <td>{row.group?.name}</td>
                            <td style={{width: "100px"}}>
                                <button className="btn" onClick={() => onClickEditButton(row.id)}><fontawesome.penToSquare /></button>
                                <button className="btn"><fontawesome.trash /></button>
                            </td>
                        </tr>)) 
                        : 
                        (<tr>
                            <td colSpan="8">Kein Daten vorhanden</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
};

export default Employees;