import { Navigate, Outlet } from "react-router";
import useAuth from "../hooks/useAuth";

const PrivateRoute = (props) => {
    const auth = useAuth();

    return(
        <>
            {auth ? <Outlet /> : <Navigate to="/login" />}
        </>
    );
};

export default PrivateRoute;