import { NavLink } from "react-router-dom";
import authService from "../services/auth.service";
import { Outlet, useNavigate } from 'react-router';
import { useSelector } from "react-redux";

const Frontend = () => {
    const headline = useSelector((state) => state.page.headline);
    let navigate = useNavigate();

    const onLogoutClick = (e) => {
        authService.logout();
        navigate("/login");
    }

    return (
        <>
            <div className="wrapper">
                <div className="sidebar">
                    <div className="sidebar-header">
                        <img src="images/logo.png" />
                    </div>
                    <div className="menu">
                        <ul>
                            <li>
                                <NavLink className="nav-link" to="/">Dashboard</NavLink>
                            </li>                                
                            <li>
                                <NavLink className="nav-link" to="/einrichtungen">Einrichtungen</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/gruppen">Gruppen</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/mitarbeiter">Mitarbeiter</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/dienstplan">Dienstplan</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/einstellungen">Einstellungen</NavLink>

                                {/**<ul>
                                    <li>
                                        <NavLink className="nav-link" to="einstellungen/listen/geschlechter">Geschlechter</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link" to="einstellungen/listen/einrichtungsarten">Einrichtungsarten</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link" to="einstellungen/listen/arbeitszeitenarten">Arbeitszeitenarten</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link" to="einstellungen/listen/ausbildungsabschluesse">Ausbildungsabschlüsse</NavLink>
                                    </li>
                                </ul>**/}
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="main-panel">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="site-title">{headline}</div>
                        <div className="buttons"><button className="btn btn-outline-default btn-sm" onClick={onLogoutClick}>Abmelden</button></div>
                    </nav>
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Frontend;