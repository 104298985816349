import { createTheme } from "@mui/material";

export const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: "2px",
                    fontSize: "13px",
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: "13px",
                }
            }
        }
    }
});
