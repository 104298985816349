import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { headline } from "../../extensions/pageSlice";
import workingtimetypesService from "../../services/workingtimetypes.service";
import bsmodal from "../../../node_modules/bootstrap/js/dist/modal";
import { useForm } from "react-hook-form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faPenToSquare, faTrash);

const WorkingTimeTypes = () => {
    const dispatch = useDispatch();
    const defaultValues = {
        id: null,
        name: "",
        short: "",
        canSelectedOnRoster: false,
    };

    const {register, handleSubmit, reset, setValue, formState: {isDirty, dirtyFields, error}} = useForm({
        defaultValues: defaultValues
    });

    const onClickEditButton = (data) => {
        reset(data);
        const modal = new bsmodal('#modal').show();
    }

    const onClickDeleteButton = (id) => {
        workingtimetypesService.deleteAsync(id)
            .then(() =>
                loadList()
            );
    }

    const handleInvalid = (data, e) => {
        console.log("Invalid");
    }

    const handleSave = (data, e) => {
        if(isDirty) {
            if(data.id == null) {
                workingtimetypesService.saveAsync(data).then(
                    () => {
                        loadList();
                        reset(defaultValues);
                    }
                );
            } else {
                var result = Object.fromEntries(Object.entries(dirtyFields).map(([k, v]) => [k, data[k]]));
                result.id = data.id;
                workingtimetypesService.updateAsync(result).then(
                    (response) => {
                        loadList();
                        reset(defaultValues);
                    }
                )
            }
        }
    }

    const [workingTimeTypes, setWorkingTimeTypes] = useState([]);

    function loadList() {
        workingtimetypesService.getAll().then(res => setWorkingTimeTypes(res.data));
    }

    useEffect(() => {
        dispatch(headline("Einstellungen - Listen - Arbeitszeitenarten"));
        loadList();
    }, []);

    return(
        <div className="panel">
            <div className="toolbar">
                <button className="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#modal">Hinzufügen</button>
            </div>
            <table className="km-table km-table-striped">
                <thead>
                    <tr>
                        <th>Bezeichnung</th>
                        <th style={{width: "100px"}}>Kürzel</th>
                        <th style={{width: "100px"}}>Dienstplan</th>
                        <th style={{width: "1%", whiteSpace: "nowrap"}}></th>
                    </tr>
                </thead>
                <tbody>
                { workingTimeTypes && workingTimeTypes.map((row, idx) => 
                    <tr key={idx}>
                        <td>{row.name}</td>
                        <td className="center">{row.short}</td>
                        <td className="center">{(row.canSelectedOnRoster) ? "Ja" : "Nein"}</td>
                        <td>
                            <div style={{whiteSpace: "nowrap"}}>
                                <button className="btn" onClick={() => onClickEditButton(row)}><FontAwesomeIcon icon="pen-to-square" /></button>
                                <button className="btn" onClick={() => onClickDeleteButton(row.id)}><FontAwesomeIcon icon="trash" /></button>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>


            <div className="modal fade" id="modal" tabIndex="-1" data-bs-keyboard="false" data-bs-backdrop="static"  aria-labelledby="modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={(e) => handleSubmit(handleSave, handleInvalid)(e)}>
                            <div className="modal-header">
                                Arbeitszeitenart hinzufügen
                            </div>
                            <div className="modal-body">
                                
                                <div className="input-group mb-3">
                                    <span className="input-group-text d-flex justify-content-center" id="nameLabel" style={{width: "160px"}}>Bezeichnung</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        placeholder="Bezeichnung" 
                                        aria-label="Name" 
                                        aria-describedby="nameLabel"
                                        maxLength="30"
                                        {...register("name", {required: true})} 
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text d-flex justify-content-center" id="shortLabel" style={{width: "160px"}}>Kürzel</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="short"
                                        placeholder="Kürzel" 
                                        aria-label="Short" 
                                        aria-describedby="shortLabel"
                                        maxLength="30"
                                        {...register("short", {required: true})} 
                                    />
                                </div>
                                <div className="form-check mb-3">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="canSelectedOnRoster"
                                        {...register("canSelectedOnRoster", {required: false})}
                                    />
                                    <label className="form-check-label" htmlFor="canSelectedOnRoster">
                                        Im Dienstplan auswählbar
                                    </label>
                                </div>                           
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-default btn-sm" data-bs-dismiss="modal">Schließen</button>
                                <button type="submit" className="btn btn-outline-default btn-sm" data-bs-dismiss="modal">Speichern</button>
                            </div>
                            <input type="hidden" name="id" {...register("id")} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WorkingTimeTypes;