import { createSlice } from "@reduxjs/toolkit";

const initalState = {
    value: '',
    headline: '',
}

export const pageSlice = createSlice({
    name: 'page',
    initialState: initalState,
    reducers: {
        headline: (state, action) => {
            state.headline = action.payload
        }
    }
});

export const { headline } = pageSlice.actions;

export default pageSlice.reducer;