import { SelectChangeEvent } from "@mui/material/Select";
import { ChangeEvent, Fragment, FunctionComponent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import TimeSelect from "../../components/timeSelect";
import { headline } from "../../extensions/pageSlice";
import rosterService from "../../services/roster.service";
import { Roster, RosterRow } from "../../types/types";
import TimeHelper from "../../helpers/TimeHelper";

export const RosterPage : FunctionComponent = () : ReactElement => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [roster, setRoster] = useState<Roster>();

    const values : Roster = {
        id: (id !== undefined && parseInt(id) > 0) ? parseInt(id) : null,
        facilityId: 0,
        isBaseRoster: false,
        valid: new Date(),
        facility: null,
        rosterRows: null,
    };

    const {register, getValues, setValue, control, handleSubmit, reset, formState: {isDirty}} = useForm({
        defaultValues: values
    });

    useEffect(() => {
        async function load() {
            if(id !== undefined && parseInt(id)) {
                await rosterService.getByIdAsync(id)
                    .then(res => {
                        console.log(res.data);
                        setRoster(res.data);
                        reset(res.data);
                    })
            }
        }

        load();
    }, []);

    function CalculateRoster(idx: number) {
        let mondayStart = getValues(`rosterRows.${idx}.mondayStart`);
        let mondayEnd = getValues(`rosterRows.${idx}.mondayEnd`);
        let mondaySum = ((mondayEnd - mondayStart) < 0) ? 0 : mondayEnd - mondayStart;
        mondaySum -= (mondaySum > 9) ? 0.25 : 0;
        mondaySum -= (mondaySum > 6) ? 0.5 : 0;
        setValue(`rosterRows.${idx}.mondaySum`, mondaySum);

        let tuesdayStart = getValues(`rosterRows.${idx}.tuesdayStart`);
        let tuesdayEnd = getValues(`rosterRows.${idx}.tuesdayEnd`);
        let tuesdaySum = ((tuesdayEnd - tuesdayStart) < 0) ? 0 : tuesdayEnd - tuesdayStart;
        tuesdaySum -= (tuesdaySum > 9) ? 0.25 : 0;
        tuesdaySum -= (tuesdaySum > 6) ? 0.5 : 0;
        setValue(`rosterRows.${idx}.tuesdaySum`, tuesdaySum);

        let wednesdayStart = getValues(`rosterRows.${idx}.wednesdayStart`);
        let wednesdayEnd = getValues(`rosterRows.${idx}.wednesdayEnd`);
        let wednesdaySum = ((wednesdayEnd - wednesdayStart) < 0) ? 0 : wednesdayEnd - wednesdayStart;
        wednesdaySum -= (wednesdaySum > 9) ? 0.25 : 0;
        wednesdaySum -= (wednesdaySum > 6) ? 0.5 : 0;
        setValue(`rosterRows.${idx}.wednesdaySum`, wednesdaySum);

        let thursdayStart = getValues(`rosterRows.${idx}.thursdayStart`);
        let thursdayEnd = getValues(`rosterRows.${idx}.thursdayEnd`);
        let thursdaySum = ((thursdayEnd - thursdayStart) < 0) ? 0 : thursdayEnd - thursdayStart;
        thursdaySum -= (thursdaySum > 9) ? 0.25 : 0;
        thursdaySum -= (thursdaySum > 6) ? 0.5 : 0;
        setValue(`rosterRows.${idx}.thursdaySum`, thursdaySum);

        let fridayStart = getValues(`rosterRows.${idx}.fridayStart`);
        let fridayEnd = getValues(`rosterRows.${idx}.fridayEnd`);
        let fridaySum = ((fridayEnd - fridayStart) < 0) ? 0 : fridayEnd - fridayStart;
        fridaySum -= (fridaySum > 9) ? 0.25 : 0;
        fridaySum -= (fridaySum > 6) ? 0.5 : 0;
        setValue(`rosterRows.${idx}.fridaySum`, fridaySum);

        let weekSumIst = mondaySum + tuesdaySum + wednesdaySum + thursdaySum + fridaySum;
        setValue(`rosterRows.${idx}.weekSumIst`, weekSumIst);

        let weekSumSoll = getValues(`rosterRows.${idx}.employee.hoursPerWeek`);

        setValue(`rosterRows.${idx}.weekSumSoll`, weekSumSoll);

        let weekSumDiff = weekSumIst - weekSumSoll;

        setValue(`rosterRows.${idx}.weekSumDiff`, weekSumDiff)
        reset(getValues());
        
    }

    const handleSelectChange = (idx: number, e: SelectChangeEvent<Number>) => {
        CalculateRoster(idx);
    }

    let groupName: string | undefined = "";

    return(
        <>
        <div className="panel">
            <form>
                <table className="roster">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th colSpan={3}>Montag</th>
                            <th colSpan={3}>Dienstag</th>
                            <th colSpan={3}>Mittwoch</th>
                            <th colSpan={3}>Donnerstag</th>
                            <th colSpan={3}>Freitag</th>
                            <th>Ist</th>
                            <th>Soll</th>
                            <th>Diff</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getValues().rosterRows && getValues().rosterRows?.map((row, idx) => {
                            if(groupName != row.group?.name) {
                                groupName = row.group?.name;
                                return (
                                    <Fragment key={idx}>
                                    <tr>
                                        <th colSpan={19} style={{textAlign: "center"}}>{row.group?.name}</th>
                                    </tr>
                                <tr key={idx}>
                                    <td>{row.employee.firstName} {row.employee.lastName}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.mondayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.mondayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.mondaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.tuesdayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.tuesdayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.tuesdaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.wednesdayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.wednesdayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.wednesdaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.thursdayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.thursdayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.thursdaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.fridayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.fridayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.fridaySum)}</td>
                                    <td>{TimeHelper.ConvertToTimeString(row.weekSumIst)}</td>
                                    <td>{TimeHelper.ConvertToTimeString(row.weekSumSoll)}</td>
                                    <td>{TimeHelper.ConvertToTimeString(row.weekSumDiff)}</td>
                                </tr>
                            </Fragment>

                        )}
                        else {
                            return (
                                <Fragment key={idx}>

                                <tr>
                                    <td>{row.employee.firstName} {row.employee.lastName}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.mondayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.mondayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.mondaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.tuesdayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.tuesdayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.tuesdaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.wednesdayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.wednesdayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.wednesdaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.thursdayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.thursdayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.thursdaySum)}</td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.fridayStart`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Kommt"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller control={control} name={`rosterRows.${idx}.fridayEnd`}
                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                <TimeSelect
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        handleSelectChange(idx, e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    text="Geht"
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>{TimeHelper.ConvertToTimeString(row.fridaySum)}</td>
                                    <td>{TimeHelper.ConvertToTimeString(row.weekSumIst)}</td>
                                    <td>{TimeHelper.ConvertToTimeString(row.weekSumSoll)}</td>
                                    <td>{TimeHelper.ConvertToTimeString(row.weekSumDiff)}</td>
                                </tr>
                            </Fragment>

                            )
                        }})}
                    </tbody>
                </table>
            </form>
            </div>
        </>
    ); 

    /*
    const {register, handleSubmit, reset, formState: {isDirty}} = useForm({
        defaultValues: defaultValues
    });


    useEffect(() => {
        (id !== undefined && parseInt(id) > 0) ? dispatch(headline("Dienstplan bearbeiten")) : dispatch(headline("Dienstplan erstellen"));

        async function load() {
            if(id !== undefined && parseInt(id)) {
                await rosterService.getByIdAsync(id)
                    .then(res => {
                        console.log(res.data);
                        setRoster(res.data);
                        reset(res.data);
                    })
            }
        }

        load();
    }, [id, dispatch]);

    let groupName: string | undefined = "";

    const onChangeTime = (e : ChangeEvent<HTMLSelectElement>) =>{
        let name = e.target.name;
        let element : Array<string> = name.split(".");
        
        let rows = roster?.rosterRows;
        let row = rows![parseInt(element[1])];
        
        row.mondaySum = row.mondayEnd - row.mondayStart;
        console.log(row.mondaySum);
        
        //reset(roster);
    }

    const handleMyClick = () => {
   

        let rows = roster?.rosterRows;
        let row = rows![0];
        row.mondayStart = 11;

        console.log(row.mondayStart);
        reset(roster);
    }
    
    return(
        <>
            <form>
            <button type="button" onClick={handleMyClick}>Halllo</button>
            <div className="panel">
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Montag</th>
                        <th>Dienstag</th>
                        <th>Mittwoch</th>
                        <th>Donnerstag</th>
                        <th>Freitag</th>
                        <th>Sonstige</th>
                        <th>Ist</th>
                        <th>Soll</th>
                        <th>Differenz</th>
                    </tr>
                    </thead>
                    <tbody>
                    {roster?.rosterRows && roster?.rosterRows.map((row, idx) => {
                        if(groupName != row.group?.name) {
                            groupName = row.group?.name;
                            return (
                                <Fragment key={idx}>
                                <tr>
                                    <th colSpan={10} style={{textAlign: "center"}}>{row.group?.name}</th>
                                </tr>
                                <tr>
                                    <td>{row.employee.firstName} {row.employee.lastName}</td>
                                    <td>
                                        <select
                                        value={roster?.rosterRows![idx].mondayStart}
                                            {...register(`rosterRows.${idx}.mondayStart`, {onChange: onChangeTime})}
                                        >
                                            <option value="7">07:00</option>
                                            <option value="7.25">07:15</option>
                                            <option value="7.5">07:30</option>
                                            <option value="7.75">07:45</option>
                                            <option value="8">08:00</option>
                                            <option value="8.25">08:15</option>
                                            <option value="8.5">08:30</option>
                                            <option value="8.75">08:45</option>
                                            <option value="9">09:00</option>
                                            <option value="9.25">09:15</option>
                                            <option value="9.5">09:30</option>
                                            <option value="9.75">09:45</option>
                                            <option value="10">10:00</option>
                                            <option value="10.25">10:15</option>
                                            <option value="10.5">10:30</option>
                                            <option value="10.75">10:45</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select value={roster?.rosterRows![idx].mondayEnd}
                                            {...register(`rosterRows.${idx}.mondayEnd` , {onChange: onChangeTime})}
                                        >
                                            <option value="7">07:00</option>
                                            <option value="7.25">07:15</option>
                                            <option value="7.5">07:30</option>
                                            <option value="7.75">07:45</option>
                                            <option value="8">08:00</option>
                                            <option value="8.25">08:15</option>
                                            <option value="8.5">08:30</option>
                                            <option value="8.75">08:45</option>
                                            <option value="9">09:00</option>
                                            <option value="9.25">09:15</option>
                                            <option value="9.5">09:30</option>
                                            <option value="9.75">09:45</option>
                                            <option value="10">10:00</option>
                                            <option value="10.25">10:15</option>
                                            <option value="10.5">10:30</option>
                                            <option value="10.75">10:45</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input value={roster?.rosterRows![idx].mondaySum} {...register(`rosterRows.${idx}.mondaySum`)} />
                                    </td>
                                </tr>
                                </Fragment>
                            )
                        } else {
                            return (
                                <tr key={idx}>
                                    <td>{row.employee.firstName} {row.employee.lastName}</td>
                                    <td>
                                        <select
                                            {...register(`rosterRows.${row.id}.mondayStart`)}
                                        >
                                            <option value="7">07:00</option>
                                            <option value="7.25">07:15</option>
                                            <option value="7.5">07:30</option>
                                            <option value="7.75">07:45</option>
                                            <option value="8">08:00</option>
                                            <option value="8.25">08:15</option>
                                            <option value="8.5">08:30</option>
                                            <option value="8.75">08:45</option>
                                            <option value="9">09:00</option>
                                            <option value="9.25">09:15</option>
                                            <option value="9.5">09:30</option>
                                            <option value="9.75">09:45</option>
                                            <option value="10">10:00</option>
                                            <option value="10.25">10:15</option>
                                            <option value="10.5">10:30</option>
                                            <option value="10.75">10:45</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            {...register(`rosterRows.${row.id}.mondayEnd`)}
                                        >
                                            <option value="7">07:00</option>
                                            <option value="7.25">07:15</option>
                                            <option value="7.5">07:30</option>
                                            <option value="7.75">07:45</option>
                                            <option value="8">08:00</option>
                                            <option value="8.25">08:15</option>
                                            <option value="8.5">08:30</option>
                                            <option value="8.75">08:45</option>
                                            <option value="9">09:00</option>
                                            <option value="9.25">09:15</option>
                                            <option value="9.5">09:30</option>
                                            <option value="9.75">09:45</option>
                                            <option value="10">10:00</option>
                                            <option value="10.25">10:15</option>
                                            <option value="10.5">10:30</option>
                                            <option value="10.75">10:45</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    </tbody>
                </table>
            </div>
        </form>



        </>
    );*/
}

export default RosterPage;