
export type User = {
    id: number;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    token: string;
}

export type Header = {
    Authorization?: string | undefined;
}

export default function authHeader() : Header {
    const user : User = JSON.parse(localStorage.getItem("user") || "");

    if(user && user.token) {
        return { Authorization: "Bearer " + user.token };
    } else {
        return {};
    }
}