import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { headline } from "../../extensions/pageSlice";
import facilitytypesService from "../../services/facilitytypes.service";
import bsmodal from "../../../node_modules/bootstrap/js/dist/modal";
import { useForm } from "react-hook-form";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faPenToSquare, faTrash);

const FacilityTypes = () => {
    const dispatch = useDispatch();
    const defaultValues = {
        id: null,
        name: "",
        short: "",
        ageFromMonth: "",
        ageToMonth: "",
        childCareRatio: "",
    };

    const {register, handleSubmit, reset, setValue, formState: {isDirty, dirtyFields, error}} = useForm({
        defaultValues: defaultValues
    });

    const onClickEditButton = (data) => {
        reset(data);
        const modal = new bsmodal('#modal').show();
    }

    const onClickDeleteButton = (id) => {
        facilitytypesService.deleteAsync(id)
            .then(() =>
                loadList()
            );
    }

    const handleSave = (data, e) => {
        if(isDirty) {
            if(data.id == null) {
                facilitytypesService.saveAsync(data).then(
                    () => {
                        loadList();
                    }
                );
            } else {
                var result = Object.fromEntries(Object.entries(dirtyFields).map(([k, v]) => [k, data[k]]));
                result.id = data.id;

                facilitytypesService.updateAsync(result).then(
                    (response) => {
                        loadList();
                        reset(defaultValues);
                    }
                )
            }
        }
    }

    const [facilityTypes, setFacilityTypes] = useState([]);

    function loadList() {
        facilitytypesService.getAll().then(res => setFacilityTypes(res.data));
    }

    useEffect(() => {
        dispatch(headline("Einstellungen - Listen - Einrichtungsarten"));
        loadList();
    }, []);

    return(
        <div className="panel">
            <div className="toolbar">
                <button className="btn btn-outline-default btn-sm" data-bs-toggle="modal" data-bs-target="#modal">Hinzufügen</button>
            </div>
            <table className="km-table km-table-striped">
                <thead>
                    <tr>
                        <th>Bezeichnung</th>
                        <th style={{width: "100px"}}>Kürzel</th>
                        <th style={{width: "100px"}}>Alter von</th>
                        <th style={{width: "100px"}}>Alter bis</th>
                        <th style={{width: "100px"}}>Betreuungsschlüssel</th>
                        <th style={{width: "1%", whiteSpace: "nowrap"}}></th>
                    </tr>
                </thead>
                <tbody>
                { facilityTypes && facilityTypes.map((row, idx) => 
                    <tr key={idx}>
                        <td>{row.name}</td>
                        <td className="center">{row.short}</td>
                        <td className="center">{row.ageFromMonth}</td>
                        <td className="center">{row.ageToMonth}</td>
                        <td className="center">{row.childCareRatio}</td>
                        <td>
                            <div style={{whiteSpace: "nowrap"}}>
                                <button className="btn" onClick={() => onClickEditButton(row)}><FontAwesomeIcon icon="pen-to-square" /></button>
                                <button className="btn" onClick={() => onClickDeleteButton(row.id)}><FontAwesomeIcon icon="trash" /></button>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>


            <div className="modal fade" id="modal" tabIndex="-1" data-bs-keyboard="false" data-bs-backdrop="static"  aria-labelledby="modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={(e) => handleSubmit(handleSave)(e)}>
                            <div className="modal-header">
                                Einrichtungsart hinzufügen
                            </div>
                            <div className="modal-body">
                                
                                    <div className="input-group mb-3">
                                        <span className="input-group-text d-flex justify-content-center" id="nameLabel" style={{width: "160px"}}>Bezeichnung</span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="name"
                                            placeholder="Bezeichnung" 
                                            aria-label="Name" 
                                            aria-describedby="nameLabel"
                                            maxLength="30"
                                            {...register("name", {required: true})} 
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text d-flex justify-content-center" id="shortLabel" style={{width: "160px"}}>Kürzel</span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="short"
                                            placeholder="Kürzel" 
                                            aria-label="Short" 
                                            aria-describedby="shortLabel"
                                            maxLength="30"
                                            {...register("short", {required: true})} 
                                        />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text d-flex justify-content-center" id="ageFromMonthLabel" style={{width: "160px"}}>Alter von</span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="ageFromMonth"
                                            placeholder="Alter ab Monaten" 
                                            aria-label="AgeFromMonth" 
                                            aria-describedby="ageFromMonthLabel"
                                            maxLength="2"
                                            {...register("ageFromMonth", {required: true})} 
                                        />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text d-flex justify-content-center" id="ageToMonthLabel" style={{width: "160px"}}>Alter bis</span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="ageToMonth"
                                            placeholder="Alter bis Monaten" 
                                            aria-label="AgeToMonth" 
                                            aria-describedby="ageToMonthLabel"
                                            maxLength="2"
                                            {...register("ageToMonth", {required: true})} 
                                        />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text d-flex justify-content-center" id="childCareRatioLabel" style={{width: "160px"}}>Betreuungsschlüssel</span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="childCareRatio"
                                            placeholder="Betreuungsschlüssel" 
                                            aria-label="ChildCareRatio" 
                                            aria-describedby="childCareRatioLabel"
                                            maxLength="30"
                                            {...register("childCareRatio", {required: true})} 
                                        />
                                    </div>
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-default btn-sm" data-bs-dismiss="modal">Schließen</button>
                                <button type="submit" className="btn btn-outline-default btn-sm" data-bs-dismiss="modal">Speichern</button>
                            </div>
                            <input type="hidden" name="id" {...register("id")} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FacilityTypes;