import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { headline } from "../../extensions/pageSlice";

const SettingsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {dispatch(headline("Einstellungen"))}, []);

    return (
        <div className="panel">
            <h1>Listen</h1>
            <div className="links-panel">
                <div className="card">
                    <div className="card-body">
                        <NavLink className="nav-link" to="/einstellungen/listen/geschlechter">Geschlechter</NavLink>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <NavLink className="nav-link" to="/einstellungen/listen/einrichtungsarten">Einrichtungsarten</NavLink>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <NavLink className="nav-link" to="/einstellungen/listen/arbeitszeitenarten">Arbeitszeitenarten</NavLink>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                    <NavLink className="nav-link" to="/einstellungen/listen/ausbildungsabschluesse">Ausbildungsabschlüsse</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsPage;