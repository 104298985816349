import axios from "axios";
import { API_URL } from "./api-url";
import authHeader from "./auth-header";

async function getAll(){
    return await axios
        .get(API_URL + "facilitytypes", {headers: authHeader()});
}

async function saveAsync(data) {
    return await axios
        .post(API_URL + "facilitytypes", data, { headers: authHeader()});
}

async function updateAsync(data) {
    return await axios
        .put(API_URL + "facilitytypes", data, { headers: authHeader()});
}

async function deleteAsync(id) {
    return await axios
        .delete(API_URL + "facilitytypes/" + id, { headers: authHeader()});
}

export default {
    getAll,
    saveAsync,
    updateAsync,
    deleteAsync,
};