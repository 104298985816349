import { Route, Routes } from "react-router";
import PrivateRoute from "./components/PrivateRoute";
import Frontend from "./layout/Frontend";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import FacilitiesPage from "./pages/Facilities";
import Genders from "./pages/Genders";
import FacilityTypes from "./pages/FacilityTypes";
import WorkingTimeTypes from "./pages/WorkingTimeTypes";
import TrainingQualifications from "./pages/TrainingQualifications";
import Employees from "./pages/Employees";
import Employee from "./pages/Employee";
import SettingsPage from "./pages/Settings";
import FacilityPage from "./pages/Facility";
import GroupsPage from "./pages/Groups";
import GroupPage from "./pages/Group";
import { RostersPage } from "./pages/Rosters";
import RosterPage from "./pages/Roster";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Frontend />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/einrichtungen" element={<FacilitiesPage />} />
            <Route path="/gruppen" element={<GroupsPage />} />
            <Route path="/mitarbeiter" element={<Employees />} />
            <Route path="/mitarbeiter/neu" element={<Employee />} />
            <Route path="/mitarbeiter/:id" element={<Employee />} />

            <Route path="/einrichtungen/neu" element={<FacilityPage />} />
            <Route path="/einrichtungen/:id" element={<FacilityPage />} />
            
            <Route path="/gruppen/neu" element={<GroupPage />} />
            <Route path="/gruppen/:id" element={<GroupPage />} />

            <Route path="/einstellungen" element={<SettingsPage />} />

            <Route path="/dienstplan" element={<RostersPage />} />
            <Route path="/dienstplan/neu" element={<RosterPage />} />
            <Route path="/dienstplan/:id" element={<RosterPage />} />

            <Route path="/einstellungen/listen/geschlechter" element={<Genders />} />
            <Route path="/einstellungen/listen/einrichtungsarten" element={<FacilityTypes />} />
            <Route path="/einstellungen/listen/arbeitszeitenarten" element={<WorkingTimeTypes />} />
            <Route path="/einstellungen/listen/ausbildungsabschluesse" element={<TrainingQualifications />} />

          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
