import axios from "axios";
import { API_URL } from "./api-url";

const login = (username, password) => {
    return axios
        .post(API_URL + "users/authenticate", {username, password})
        .then((response) => {
            if(response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
}

export default {
    login,
    logout
};