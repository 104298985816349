import { useDispatch } from "react-redux";
import { headline } from "../../extensions/pageSlice";
import { useEffect } from "react";

const Dashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("Dispatch Dasboard");
        dispatch(headline("Dashboard"));
    }, []);

    return(
        <>
        Dashboard
        </>
    );
};

export default Dashboard;