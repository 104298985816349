import { useEffect, useState } from "react";
import facilitiesService from "../services/facilities.service";

const FacilitySelect = (props) => {
    const [facilities, setFacilities] = useState([]);

    function  loadFacilities() {
        facilitiesService.getAll().then(
            res => {
                setFacilities(res.data); 
                props.onAfterLoad();
            } 
        );
    }

    useEffect(() => {
        loadFacilities();
    }, [])

    return(
        <select id={props.name} ref={props.innerRef} name={props.name} onChange={props.onChange} onBlur={props.onBlur} className={props.className}>
            <option value="">Bitte wählen</option>
            {facilities && facilities.map((opt, idx) => 
                <option key={idx} value={opt.id}>{opt.name}</option>
            )}
        </select>
    )
}

export default FacilitySelect;