import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import employeesService from "../../services/employees.service";
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from "react";
import { headline } from "../../extensions/pageSlice";
import { useDispatch } from "react-redux";
import Select, { SelectOption } from "../../components/Select";
import gendersService from "../../services/genders.service";
import facilitiesService from "../../services/facilities.service";
import groupsService from "../../services/groups.service";
import trainingqualificationsService from "../../services/trainingqualifications.service";
import IEmployee from "../../types/IEmployee";
import { AxiosError } from "axios";
import DateHelper from "../../helpers/DateHelper";

export const Employee : FunctionComponent = () : ReactElement => {
    const dispatch = useDispatch();
    const saveButton = useRef<any>();
    const labelWidth = "170px";
    const { id } = useParams();
    const [error, setError] = useState<string[]>([]);
    const [genders, setGenders] = useState<Array<SelectOption>>([]);
    const [facilities, setFacilities] = useState<Array<SelectOption>>([]);
    const [groups, setGroups] = useState<Array<SelectOption>>([]);
    const [trainingQualifications, setTrainingQualifications] = useState<Array<SelectOption>>([]);
    const defaultValues : IEmployee = {
        id: (id !== undefined && parseInt(id) > 0) ? id : null,
        staffNumber: "",
        firstName: "",
        lastName: "",
        forename: "",
        maidenName: "",
        transponder: "",
        hoursPerWeek: 0,
        genderId: null,
        trainingQualificationId: null,
        birthdate: null,
        facilityId: null,
        groupId: null,
    };
    const {register, handleSubmit, reset, formState: {isDirty}} = useForm({
        defaultValues: defaultValues
    });
    const gender = register('genderId', {required: false});
    const facility = register('facilityId', {required: false});
    const trainingQualification = register('trainingQualificationId', {required: false});
    const group = register('groupId', {required: false});

    function formatDate(date : string) {
        let d = new Date(date);
        let day = (d.getDate() < 10) ? "0" + d.getDate() : d.getDate();
        let month = (d.getMonth() < 9) ? "0" + (d.getMonth()+1) : (d.getMonth()+1);
        let year = d.getFullYear();
        return day + "." + month + "." + year;
    }

    useEffect(() => {
        (id !== undefined && parseInt(id) > 0 ) ? dispatch(headline("Mitarbeiter bearbeiten")) : dispatch(headline("Mitarbeiter hinzufügen"));
        async function load() {
            await gendersService.getAll().then(
                res => {
                    setGenders(res.data);
                    console.log(1);
                } 
            ).catch((err : AxiosError) => setError(oldErrors => [...oldErrors, err.message]));
    
            await facilitiesService.getAll().then(
                res => {
                    setFacilities(res.data);
                    console.log(2);
                }
            ).catch((err : AxiosError) => setError(oldErrors => [...oldErrors, err.message]));
    
            await groupsService.getAll().then(
                res => {
                    setGroups(res.data);
                    console.log(3);
                }
            ).catch((err : AxiosError) => setError(oldErrors => [...oldErrors, err.message]));
    
            await trainingqualificationsService.getAll().then(
                res => {
                    setTrainingQualifications(res.data);
                    console.log(4);
                }
            ).catch((err : AxiosError) => setError(oldErrors => [...oldErrors, err.message]));
    
            if(id !== undefined && parseInt(id) > 0) {
                await employeesService.getByIdAsync(id)
                    .then(res => {
                        let data : IEmployee = res.data;
                        data.birthdateString = DateHelper.format(data.birthdate);
                        reset(data);
                        saveButton.current.disabled = (saveButton.current) ? true : null;
                        console.log("Mitarbeiter geladen");
                    })
                    .catch((err : AxiosError) => setError(oldErrors => [...oldErrors, err.message]));
            }
        }
        load();
    }, [id, dispatch, reset]);

    useEffect(() => {
        saveButton.current.disabled = (saveButton.current) ?  false : null;
    }, [isDirty])

    const handleSave = (data : IEmployee) => {
        if(isDirty) {
            if(data.id == null) {
                data.birthdate = DateHelper.parse(data.birthdateString);
                employeesService.saveAsync(data);
            } else {
                data.birthdate = DateHelper.parse(data.birthdateString);
                employeesService.updateAsync(data);
            }
        }
    }

    return(
        <form onSubmit={handleSubmit(handleSave)}>

            {error && error.map((err, idx) => (
                <div key={idx} className="alert alert-danger" role="alert">
                    {err}
                </div>
            ))}

            <div className="panel">
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="firstNameLabel" style={{width: labelWidth}}>Vorname</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Vorname" 
                                maxLength={30}
                                {...register("firstName", {required: true})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                    <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="lastNameLabel" style={{width: labelWidth}}>Nachname</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Nachname" 
                                maxLength={30}
                                {...register("lastName", {required: true})} 
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="forenameLabel" style={{width: labelWidth}}>Rufname</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Rufname" 
                                maxLength={30}
                                {...register("forename", {required: false})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                    <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="maidenNameLabel" style={{width: labelWidth}}>Geburtsname</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Geburtsname" 
                                maxLength={30}
                                {...register("maidenName", {required: false})} 
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="forenameLabel" style={{width: labelWidth}}>Geschlecht</span>
                            <Select 
                                options={genders}
                                className="form-control"
                                handleBlur={gender.onBlur}
                                handleChange={gender.onBlur}
                                name={gender.name}
                                innerRef={gender.ref}

                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                    <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="maidenNameLabel" style={{width: labelWidth}}>Geburtsdatum</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Geburtsdatum" 
                                maxLength={10}
                                {...register("birthdateString", {required: false})} 
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="staffNumberLabel" style={{width: labelWidth}}>Personalnummer</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Personalnummer" 
                                maxLength={10}
                                {...register("staffNumber", {required: false})} 
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                    <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="transponderLabel" style={{width: labelWidth}}>Transponder</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Transponder-Nummer" 
                                maxLength={10}
                                {...register("transponder", {required: false})} 
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="staffNumberLabel" style={{width: labelWidth}}>Ausbildungsabschluss</span>
                            <Select 
                                options={trainingQualifications}
                                className="form-control"
                                handleBlur={trainingQualification.onBlur}
                                handleChange={trainingQualification.onBlur}
                                name={trainingQualification.name}
                                innerRef={trainingQualification.ref}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                    <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="hoursOfWeekLabel" style={{width: labelWidth}}>Wochenstunden</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Wochenarbeitsstunden" 
                                maxLength={5}
                                {...register("hoursPerWeek", {required: false})} 
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="facilityLabel" style={{width: labelWidth}}>Einrichtung</span>
                            <Select 
                                options={facilities}
                                className="form-control"
                                handleBlur={facility.onBlur}
                                handleChange={facility.onBlur}
                                name={facility.name}
                                innerRef={facility.ref}

                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                        <div className="input-group mb-3">
                            <span className="input-group-text d-flex justify-content-center" id="groupLabel" style={{width: labelWidth}}>Gruppe</span>
                            <Select 
                                options={groups}
                                className="form-control"
                                handleBlur={group.onBlur}
                                handleChange={group.onBlur}
                                name={group.name}
                                innerRef={group.ref}
                            />
                        </div>
                    </div>
                </div>

                <button ref={saveButton} disabled type="submit" className="btn btn-outline-default btn-sm">Speichern</button>
            </div>
            <input type="hidden" {...register("id", {required: false})} />
        </form>
    )
}

export default Employee;