function ConvertToTimeString(time: number) : string {
    let h = (time>=0) ? Math.floor(time) : Math.ceil(time);
    let m = 60 * (time - h);
    m = (m<0) ? m*-1 : m;
    let hour = (h < 10 && h >= 0) ? "0" + h : h.toString();
    let min = (m<10) ? "0" + m : m.toString();

    return `${hour}:${min}`;
}

export default {
    ConvertToTimeString
}