import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useAuth from '../../hooks/useAuth';
import authService from '../../services/auth.service';

const Login = () => {
    let navigate = useNavigate();

    useEffect(() => {
        const IsLoggedIn = () => {
            if(useAuth()) {
                navigate("/")
            }
        };
        IsLoggedIn();
    });

    const { register, handleSubmit, formState: {error}} = useForm();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    }

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    }

    const handleLogin = (e) => {
        setMessage("");
        setLoading(true);
        authService.login(username, password).then(
            () => {
                navigate("/");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) || 
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    return(
        <>
            <div className="container-fluid w-100 h-100">
                <div className="row d-flex vh-100 bg-white">
                    <div className="col-sm-12 col-md-6 bg-light flex-grow-1 d-flex justify-content-center align-items-center">
                    <form onSubmit={(e) => handleSubmit(handleLogin)(e)}>
                            <div className='card shadow'>
                                <div className='card-header d-flex justify-content-center'><h1 className='p-0 m-0' style={{fontSize: "1.5em"}}>Kita-Manager</h1></div>
                                <div className='card-body'>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text  d-flex justify-content-center" id="usernameLabel" style={{width: "110px"}}>Benutzer</span>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="username"
                                            placeholder="Benutzer" 
                                            aria-label="Username" 
                                            aria-describedby="usernameLabel"
                                            {...register("username", {required: true, value: username, onChange: onChangeUsername})} 
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text d-flex justify-content-center" id="passwordLabel" style={{width: "110px"}}>Kennwort</span>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            placeholder="Kennwort" 
                                            aria-label="Password" 
                                            aria-describedby="passwordLabel" 
                                            {...register("password", {required: true, value: password, onChange: onChangePassword})} 
                                        />
                                    </div>
                                </div>
                                <div className='card-footer d-flex justify-content-end w-100'>
                                    <button type='submit' className='btn btn-outline-default btn-sm' disabled={loading}>Anmelden</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="d-none d-lg-block col-md-6 bg-dark flex-grow-1 login-background">
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;