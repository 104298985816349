import {  LegacyRef, ChangeEventHandler, FocusEventHandler, FunctionComponent, ReactElement, ReactEventHandler} from "react";

export interface SelectProps {
    options: Array<SelectOption>;
    handleSelect? : ReactEventHandler<HTMLSelectElement>;
    handleChange? : ChangeEventHandler<HTMLSelectElement>;
    handleBlur? : FocusEventHandler<HTMLSelectElement>;
    defaultValue? : number;
    value? : number;
    name? : string;
    innerRef : LegacyRef<HTMLSelectElement>;
    className : string;
}

export type SelectOption = {
    name : number | string;
    id : number | string;
}

export const Select : FunctionComponent<SelectProps> = (props) : ReactElement => {

    return(
        <>
            <select onBlur={props.handleBlur} onChange={props.handleChange} name={props.name} value={props.value} defaultValue={props.defaultValue} ref={props.innerRef} className={props.className}>
                <option>Bitte wählen</option>
                {props.options && props.options.map((option, idx) => (
                    <option key={idx} value={option.id}>{option.name}</option>
                ))}
            </select>
        </>
    )
};

export default Select;