import { useEffect, useState } from "react";
import facilitytypesService from "../services/facilitytypes.service";

const FacilityTypeSelect = (props) => {
    const [facilityTypes, setFacilityTypes] = useState([]);

    function loadData() {
        facilitytypesService.getAll().then(
            res => {
                setFacilityTypes(res.data);
                props.onAfterLoad();
            }
        );
    }

    useEffect(() => {
        loadData();
    }, []);
    
    return(
        <select id={props.name} ref={props.innerRef} name={props.name} onChange={props.onChange} onBlur={props.onBlur} className={props.className}>
            <option value="">Bitte wählen</option>
            {facilityTypes && facilityTypes.map((opt, idx) =>
                <option key={idx} value={opt.id}>{opt.name}</option>
            )}
        </select>
    )
}

export default FacilityTypeSelect;